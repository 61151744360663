
























import { Thing } from '../../../models/Thing';
import { Unit } from '../../../models/Unit';
import ConnectUnitDialog from './ConnectUnitDialog.vue';
import Vue from 'vue';

export default Vue.extend({
  name: 'ConnectUnitDialogAction',

  components: { ConnectUnitDialog },

  props: {
    unit: {
      required: true,
      type: Unit,
    },
    thing: {
      type: Thing,
      required: true,
    },
  },
});
